:root {
  --color1: #FFFFFF; /* white */
  --color2: #0B5AAB; /* blue */
  --color3: #000000; /* black */
  --color4: #8EC549; /* green */
  --color5: #E68719; /* orange */
  --color51: #FFF0DA; /* light orange */
  --color6: #CB2020; /* red */
  --color61: #F3DEE0; /* light red */
}

/*.App {*/
/*  text-align: center;*/
/*  !*font-family: "Roboto", "Helvetica", "Arial", sans-serif;*!*/
/*}*/

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

h1.pageTitle {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 0;
}

h2.subTitle {
  font-size: 1.3rem;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
}

pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.title2 {
  font-size: 1.7rem;
  font-weight: normal;
  margin: 10px auto;
}

.title3 {
  font-size: 1.15rem;
  font-weight: normal;
}

/* MAIN LAYOUT (START) */
#appWrapper {
  height: 100vh;
  display: flex;
  /* Direction of the items, can be row or column */
  flex-direction: column;
}

#appHeader {
  background-color: var(--color1);
  color: var(--color2);
}

#appHeader #logoContainer {
  flex: 1; /* This element will have the remaining full space given to it */
  text-align: left;
  padding-top: 8px;
}

#appHeader #logoContainer .logo {
  height: 40px;
  margin-left: 4vw;
}

#appHeader #linksContainer {
  margin-right: 4vw;
}

#appHeader #secondaryToolBar {
  background-color: var(--color2);
  color: var(--color1);
}

#appHeader #secondaryToolBar #headerButtonsContainer {
  margin-left: 4vw;
}

#appSubHeader {
  margin: 0vh auto;
  padding: 1.5vh;
  font-size: 14pt;
  width: 100%;
  flex-direction: column;
  border-bottom: 2px solid #aaaaaa;
}

#appContent {
  color: var(--color3);
  flex: 1;
  width: 92%;
  margin: 0vh auto;
  margin-bottom: 8vh;
}

#appFooter {
  padding-top: 5vh;
  padding-bottom: 5vh;
  background-color: #0B5AAB;
  text-align: center;
  color: var(--color1);
  font-size: 1rem;
}

#appFooter a {
  color: var(--color1);
}
/* MAIN LAYOUT (END) */

/* BUTTONS (START) */

#mainButtons {
  margin: auto;
  text-align: center;
}

.mainButton {
  margin: 8vh 1vw;
}

#findMetadataButtons,
#evaluateButton,
#submitChangesButton {
  margin: auto;
  text-align: center;
}

.generalButton {
  margin: 1vh 1vw;
}

.iconButton {
  color: var(--color2);
}

.sampleInputButton {
  text-align: right;
  width: 60vw;
  margin: -30px auto auto;
  text-transform: none;
}

.floatindContinueButton {
  width: 20%;
  background-color: #eeeeee;
  float: left;
  text-align: center;
  margin-top: 8vh;
  margin-left: -100px;
  padding: 10px;
  border: 1px solid #dddddd;
}

/* BUTTONS (END) */

.inputTextFieldContainer {
  text-align: center;
  margin: 3vh auto;
  width: 65%;
}

#mainInputTextField {
  font-size: 0.8rem;
  line-height: 0.9rem;
  width: 60vw;
}

#inputMetadataUris {
  font-size: 0.8rem;
  line-height: 0.9rem;
  width: 60vw;
}

#inputTemplateId {
  font-size: 0.8rem;
  line-height: 0.9rem;
  width: 60vw;
}

.mainTitle {
  margin-top: 8vh;
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
}

.mainSubtitle {
  text-align: center;
  margin: 1vh auto;
  font-size: 1.5rem;
}

.aboutText {
  width: 65%;
  float: left;
  margin-right: 8rem;
}

.aboutLogo {
  width: 25%;
  float: left;
}

.paragraph {
  margin-top: 2vh;
}

.searchResults,
.recommendationResults,
.alignmentResults,
.evaluationResults {
  margin-top: 3vh;
}

.table {
  margin: 0vh auto;
}

.header {
  font-size: 13pt;
  padding: 20px 0 20px 0;
  text-align: center;
}

.cell {
  font-size: 12pt;
}

.center {
  text-align: center;
}

.wrapIcon {
  padding: 0.1rem 0.4rem;
  display: inline-flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 0.2rem;
}

.wrapIconWarning {
  background-color: var(--color51);
  color: var(--color5);
}

.wrapIconError {
  background-color: var(--color61);
  color: var(--color6);
}

.textIcon {
  margin-left: 0.3rem;
}

.textWarning {
  color: var(--color5);
}

.textSuccess {
  color: var(--color4);
}

.textError {
  color: var(--color6);
}

/* SUMMARY REPORT */

.summaryReportPanel {
  padding: 2vh;
}

.summaryReportPanel .progressIndicator {
  margin-top: 12vh;
  margin-bottom: 12vh;
  text-align: center;
}

.horizontalBarCell {
  font-weight: bold;
  font-size: 0.8rem;
}

.materialTooltip {
  margin-left: 0px;
  vertical-align: -4px; /*Change this to adjust the icon*/
}




